<template>
  <img class="spinnerino" alt="Vue logo" src="https://i.imgur.com/RxXoLnC.png" width="700" height="700">
  <HelloWorld msg="Media, Take the $100"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.spinnerino {
  border-radius: 50%;
  -webkit-animation: spin 0.0001s linear infinite;
  animation: spin 0.0001s linear infinite;
}


@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg);}
  100% { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(360deg); }
}
</style>
