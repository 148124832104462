<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      Media just take the $100 for mazds sake
    </p>
    <h3>Benefits of this deal</h3>
    <ul>
      <ul>
        <li>I come to the podcast</li><br/>
        <li>Anything else</li>
      </ul>
      
    </ul>
    <h3>Drawbacks</h3>
    <ul>
      I see no drawbacks
    </ul>
    <h3>Beneficiaries</h3>
    <ul>
      <li>Literally everyone benefits</li>
    </ul>

    <audio controls autoplay> 
      <source src="../assets/music.mp3" type="audio/mpeg" />
    </audio>
    <br/><br/>

    <u><strong>Now featuring <del>background</del> music</strong></u> (Since it wasn't consistent in autoplaying the music) <br/><br/>
    <strong>peak speed is: 0.0001s ;)</strong>
  </div>

  
</template>

<script>

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

</style>
